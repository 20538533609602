import { Avatar, Box, Grid, Typography } from '@mui/material'
import React from 'react'

interface Props {
    name: string
    description: string
    url: string
    img: string
}
export const Item = ({ name, description, url, img }: Props) => {
    return (
        <Grid item xs={12} sm={6} md={4} lg={3}
            sx={{
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                cursor: 'pointer',
                gap: 1,
                textAlign: "center"
            }}
            onClick={() => { window.open(url, '_blank') }}
        >
            <Avatar
                sx={{ width: 80, height: 80 }}
                src={img}
                alt={name}
            />
            <Typography variant='h6' fontWeight={"500"}>
                {name}
            </Typography>
            <Typography variant='subtitle2' color={"rgba(0,0,0,0.5)"}>
                {description}
            </Typography>
        </Grid>
    )
}
