// import { blue, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#cc3333',
            contrastText: "#fff",
            light: "#0099ff",
            dark: "#992727"
        },
        secondary: {
            main: '#049cfc',
            contrastText: "#000",
            light: "#cc3333",
            dark: "#0375bd"
        },
        info: {
            main: '#002e54',
            contrastText: "#fff",
            light: "#049cfc",
            dark: "#00233f"
        }
    },
});