import React from 'react'
import { Box } from '@mui/material'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Footer } from '../components/Footer'
import { Home } from '../pages/Home'
import { TNavBar } from "../components/NavBar/NavBar"
import { Participants } from '../pages/Participants'
import { GameList } from '../pages/GameList'
import { News } from '../pages/News'
import { NewsItem } from '../pages/NewsItem'
export const AppRouter = () => {
    return (
        <Box>

            <Box
                sx={{
                    backgroundColor: "#fff",
                    minHeight: "100vh",
                    color: '#000'
                }}
            >
                <TNavBar>
                    <Routes>
                        <Route path={`/`} element={<Home></Home>} />
                        <Route path={`/participantes`} element={<Participants></Participants>} />
                        <Route path={`/listado-videojuegos`} element={<GameList></GameList>} />
                        <Route path={`/noticias`} element={<News></News>} />
                        <Route path={`/noticias/:name`} element={<NewsItem></NewsItem>} />




                        <Route path={`/*`} element={<Navigate to={"/"}></Navigate>} />
                    </Routes >
                </TNavBar>
            </Box>
            <Footer />
        </Box>
    )
}
