import { Box, Typography } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const NewsItem = () => {
    const [fileContent, setFileContent] = useState("");
    const params = useParams()
    useEffect(() => {
        const fetchFile = async () => {
            try {
                if (params.name) {
                    const name = params.name.replace(/-/g, " ") + ".md"
                    console.log(name);
                    const readmePath = require(`../News/${name}`);
                    const response = await fetch(readmePath);
                    const text = await response.text();
                    console.log(text);

                    setFileContent(text);
                }
            } catch (error) {
                console.error("Error al obtener el archivo:", error);
            }
        };
        fetchFile();
    }, [])

    return (
        <Box
            sx={{
                paddingX: {
                    xl: 30,
                    lg: 20,
                    md: 20,
                    sm: 10,
                    xs: 5
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                mt: 4
            }}
        >
            <Box>
                <Typography color={'primary'} variant='h3' fontWeight={500}>{params.name && params.name.replace(/-/g, " ")}</Typography>
            </Box>
            <Box
                data-color-mode="light"
                sx={{
                    "img": {
                        maxHeight: 350,
                        my: 4,
                        fontSize: "1.3rem",
                        lineHeight: "25px",
                        wordSpacing: "0.25em"
                    },
                }}
            >
                <MDEditor.Markdown
                    source={fileContent}
                    style={{ whiteSpace: "pre-wrap", paddingLeft: '2rem', paddingRight: '2rem' }}
                />
            </Box>
        </Box>

    )
}
