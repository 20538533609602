import React from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import image from "../../assets/imagen1.jpg"
import { Item } from './item'

export const GameListContainer = () => {
    const games = [
        {
            name: "Games Ground",
            author: "Tesseract",
            description: "Hypercasual MMO - Aventurate con tus amigos a distintos mundos y diviertete participando en diversos minijuegos",
            image: "https://gamesground.tesseractsoftwares.com/static/media/GG_mapa_1.b1c64c68ab20c8d0eaf1.png",
            url: "https://gamesground.tesseractsoftwares.com"
        },
        {
            name: "JanKenUP!",
            author: "Humita Games",
            description: "Compite en electrizantes duelos de piedra, papel o tijera con un excéntrico elenco de personajes. Supera tus records en el modo single player o desafía a tus amigos en partidas online",
            image: "https://pbs.twimg.com/media/FvrsPSyX0AE2QQ2?format=jpg&name=4096x4096",
            url: "https://jankenup.com/"
        },
        {
            name: "Kyubu Kyubu Dice",
            author: "Sleepy Dog Games",
            description: 'Kyubu Kyubu Dice es un juego puzzle de acción que te desafía a pensar espacialmente! Al hacer rodar el dado mágico "Kyubu", el jugador debe despejar los paneles que aparecen en la etapa! Pero cuidado, a medida que avanza el juego, los paneles se generan cada vez más rápido, volviéndose bastante frenético!',
            image: "https://cdn.cloudflare.steamstatic.com/steam/apps/2120220/header.jpg?t=1677099864",
            url: "https://store.steampowered.com/app/2120220/Kyubu_Kyubu_Dice/"
        },
        {
            name: "Wekufu",
            author: "Stickerb Games E.I.R.L.",
            description: 'Un Oscuro Juego de Plataformas Hack and Slash Dibujado a Mano. Toma el rol de Sakinko, una joven guerrera en camino a vengar la muerte de su padre y proteger a su pueblo de los espíritus malignos que amenazan su tierra. Estos espíritus malignos llevan el nombre de Wekufu',
            image: "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/51b2fd27-6d52-4afd-a9a9-7c862a9df49e/dfxaqam-fd5ac9b8-8fa9-4de0-8ad4-4a12fb5ff1d6.jpg/v1/fill/w_894,h_894,q_70,strp/game_cover_squared_01_s_by_stickerb_dfxaqam-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTA4MCIsInBhdGgiOiJcL2ZcLzUxYjJmZDI3LTZkNTItNGFmZC1hOWE5LTdjODYyYTlkZjQ5ZVwvZGZ4YXFhbS1mZDVhYzliOC04ZmE5LTRkZTAtOGFkNC00YTEyZmI1ZmYxZDYuanBnIiwid2lkdGgiOiI8PTEwODAifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.CLAiEAtazqblpRQxqc4aNb0eNt4yWNZ-U7jE_7KL0hU",
            url: "https://www.instagram.com/wekufu_game/"
        },
        {
            name: "Void Marauders",
            author: "Autarca",
            description: "Void Marauders es un juego táctico por turnos donde lideras a una banda de piratas espaciales para cazar y saquear tu camino a la victoria.",
            image: "https://i.imgur.com/kebQVRd.jpeg",
            url: "https://twitter.com/AutarcaDev"
        },
        {
            name: "Massive Warfare Aftermatch",
            author: "Tiny Bytes",
            description: "Massive Warfare is the award winning ultimate mobile war game, and the only mobile shooter game where players can have real-time multiplayer battles with all air, ground and water military vehicles in one same game. Players can customise and enhance their war machines with tons of upgrades, cammouflages, stickers, specific skills for each vehicle, active and passive tech boosters and many more items. They can team up with other players, form Alliances and chose between multiple strategies to overcome their rivals in different game modes (deathmatch, team battles, domination, CTF, and many more) in varied environments",
            image: "https://www.massivewarfare.com/images/aftermath_header.jpg",
            url: "https://www.massivewarfare.com/"
        },
    ]
    return (
        <Box
            sx={{
                paddingX: {
                    xl: 30,
                    lg: 20,
                    md: 20,
                    sm: 10,
                    xs: 5
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                mt: 4
            }}
        >
            {
                games.map((item, index) => {
                    return <Item key={index} name={item.name} description={item.description} author={item.author} url={item.url} image={item.image} />
                })
            }

        </Box>
    )
}
