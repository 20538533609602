import { Avatar, Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Item } from './Item'
export const Desarrolladores = () => {
    const users = [
        {
            name: "Vincent Depassier",
            description: "CEO",
            url: "https://www.linkedin.com/in/vincent-depassier/",
            img: "https://media.licdn.com/dms/image/D4E03AQEpSg55vcs3Ag/profile-displayphoto-shrink_200_200/0/1669690324521?e=1689811200&v=beta&t=fqRLvTYuNPmngnqWfF0n-24YzO087NsrmFK_1O3_Emc"
        },
        {
            name: "Claudio Esteban González Rojas",
            description: "CEO",
            url: "https://www.youtube.com/@Kyotenks",
            img: "https://yt3.googleusercontent.com/uKz5dURlvuAhjkBZ7y6f03VP1JE_eFjOR9gvvzCIqnQ8kXuBWqkvSQBvZ9Z8-HV7riY-f3LSmHQ=s176-c-k-c0x00ffffff-no-rj"
        },
        {
            name: "Ignacio Arancibia",
            description: "Desarrollador Independiente",
            url: "https://store.steampowered.com/app/2120220/Kyubu_Kyubu_Dice/",
            img: "https://lh3.googleusercontent.com/u/0/drive-viewer/AFGJ81rYLjztGiIZ9KHct0_LvP2s0aUG8r90YB9aTmXnais4HV9Y6zT1NLRe5xRvIDnFb0k-taNhjOxJYkHD3X0ulYR20jNP-Q=w2560-h1198"
        },
        {
            name: "Esteban Campos",
            description: "Desarrollador",
            url: "https://www.instagram.com/wekufu_game/",
            img: "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/51b2fd27-6d52-4afd-a9a9-7c862a9df49e/dfxapzb-447a7bdc-4798-4b7c-a732-bed122b5d4f6.jpg/v1/fill/w_894,h_894,q_70,strp/stb_logo_1024_by_stickerb_dfxapzb-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTAyNCIsInBhdGgiOiJcL2ZcLzUxYjJmZDI3LTZkNTItNGFmZC1hOWE5LTdjODYyYTlkZjQ5ZVwvZGZ4YXB6Yi00NDdhN2JkYy00Nzk4LTRiN2MtYTczMi1iZWQxMjJiNWQ0ZjYuanBnIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.5SqzeFr31cSKZInYQdBM7if21Go7Gcm5lmaXW8bWl0c"
        },
        {
            name: "Jorge Pacheco",
            description: "Dueño",
            url: "https://twitter.com/AutarcaDev",
            img: "https://i.imgur.com/q2Q2QOk_d.webp"
        },
        {
            name: "Andres Constantinidis",
            description: "CEO",
            url: "https://www.tinybytes.com/",
            img: "https://www.tinybytes.com/"
        },

    ]
    return (
        <Box>
            <Typography variant='h4' fontWeight={"500"} textAlign={"center"}>
                Desarrolladores
            </Typography>
            <Grid container spacing={4} my={2}>
                {
                    users.map((item, index) => {
                        return (
                            <Item name={item.name} description={item.description} url={item.url} img={item.img} />
                        )
                    })
                }
            </Grid>
        </Box>
    )
}
