import React from 'react'
import image from '../../assets/imagen1.jpg'
import { Box } from '@mui/material'
import { Item } from './Item'
export const NewsList = () => {
    // const news = [
    //     // {
    //     //     name: "Games Ground",
    //     //     author: "Tesseract",
    //     //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    //     //     image: image,
    //     // }
    // ]
    return (
        <Box
            sx={{
                paddingX: {
                    xl: 60,
                    lg: 40,
                    md: 20,
                    sm: 10,
                    xs: 5
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                mt: 4
            }}
        >
            {/* {
                news.map((item, index) => {
                    return <Item key={index} name={item.name} description={item.description} author={item.author} image={item.image} />
                })
            } */}

        </Box>
    )
}
