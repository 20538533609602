import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { appTheme } from '../../styles/MUI/Theme';
import { Avatar, Button, Divider, Drawer, IconButton, List, Menu, MenuItem, ThemeProvider, Tooltip } from '@mui/material';
import "../../styles/CSS/Nav.css"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavRoutes } from './NavItems';
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TDrawerItemsList from './TDrawerItemsList';
import { DropDownItem } from './DropdownItem';
import mainLogo from "../../assets/Logo VGCWeek - Variante_01-FondoClaro.png"

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}
const drawerWidth = 350;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

function ElevationScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

export function TNavBar(props: Props) {
    const theme = useTheme();
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()
    const location = useLocation()

    React.useEffect(() => {
        setOpen(false)
    }, [location.pathname])

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };



    return (
        <div>
            <ThemeProvider theme={appTheme}>
                <React.Fragment >
                    <CssBaseline />
                    <ElevationScroll {...props}>
                        <div>
                            <AppBar color='primary' className='customNav'>
                                <Toolbar sx={{ justifyContent: "space-between" }}>
                                    <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} >
                                        <Link to={`/`} className='logoNav' >
                                            <Box
                                                sx={{
                                                    width: 200,
                                                    "img": {
                                                        width: '100%'
                                                    }
                                                }}
                                            >
                                                <img src={mainLogo} alt="Bercomp Logo" />
                                            </Box>
                                        </Link>
                                    </Box>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="end"
                                        onClick={handleDrawerOpen}
                                        // sx={{ ...(open && { display: 'none' }) }}
                                        sx={{ display: { xs: "flex", md: "none" } }}
                                    >
                                        <MenuIcon color='secondary' />
                                    </IconButton>
                                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                        <NavRoutes />
                                        {/* <DropDownItem /> */}
                                    </Box>
                                </Toolbar>

                            </AppBar>
                            <Drawer
                                PaperProps={{
                                    sx: {
                                        backgroundColor: "#ffffff",
                                        color: "#231f20",
                                        display: { xs: "block", md: "none" }
                                    }
                                }}

                                sx={{
                                    width: drawerWidth,
                                    flexShrink: 0,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,
                                    },
                                }}
                                variant="persistent"
                                anchor="left"
                                open={open}
                                color="primary"
                            >
                                <DrawerHeader sx={{ justifyContent: "space-between" }}>
                                    <Box sx={{ display: { xs: "flex", md: "none" }, m: 1 }} >
                                        <Link to="/" className='logoNav' >
                                            <Box
                                                sx={{
                                                    width: 150,
                                                    "img": {
                                                        width: '100%'
                                                    }
                                                }}
                                            >
                                                <img src={mainLogo} alt="Bercomp Logo" />
                                            </Box>
                                            {/* <Typography variant="h6" component="div" sx={{ fontFamily: "Roboto !important", fontWeight: "700" }}>
                                                TESSERACT
                                            </Typography> */}
                                        </Link>
                                    </Box>
                                    <IconButton onClick={handleDrawerClose} >
                                        {theme.direction === 'rtl' ? <ChevronLeftIcon style={{ color: "#231f20" }} /> : <ChevronRightIcon style={{ color: "#231f20" }} />}
                                    </IconButton>

                                </DrawerHeader>
                                <Divider />
                                <List component="nav">

                                    <TDrawerItemsList />
                                </List>
                            </Drawer>
                        </div>
                    </ElevationScroll>
                    {/* <Toolbar /> */}
                    <Box sx={{ py: { xs: 9, sm: 10 } }}>
                        {props.children}
                    </Box>
                </React.Fragment>
            </ThemeProvider>
        </div>
    );
}