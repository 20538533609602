import { Avatar, Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Item } from './Item'
export const Colaboradores = () => {
    const users = [
        {
            name: "Lady Ixel",
            description: "Fundadora",
            url: "https://www.instagram.com/lady_ixel/",
            img: "https://drive.google.com/file/d/1m_MW8NOl3nQrhh1Ophs9paapheOojq9g"
        },
    ]
    return (
        <Box>
            <Typography variant='h4' fontWeight={"500"} textAlign={"center"}>
                Colaboradores
            </Typography>
            <Grid container spacing={4} my={2}>
                {
                    users.map((item, index) => {
                        return (
                            <Item name={item.name} description={item.description} url={item.url} img={item.img} />
                        )
                    })
                }
            </Grid>
        </Box>
    )
}
