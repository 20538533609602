import React from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import image from '../../assets/imagen1.jpg'

export const Desarrollo = () => {
    return (
        <Box
            sx={{
                paddingX: {
                    xl: 60,
                    lg: 40,
                    md: 20,
                    sm: 10,
                    xs: 5
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2
            }}
        >
            <Box
                sx={{
                    // height: '250',
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: "center center"
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "rgba(0,0,0,0.6)",
                        p: {xs:3,md:5}
                    }}
                >
                    <Typography variant='h3' textAlign={'center'} color={"#fff"}>
                        ¿Quieres desarrollar videojuegos en Chile?
                    </Typography>
                </Box>
            </Box>
            <Paper elevation={6} sx={{ p: 3 }}>
                <Typography variant='body1' fontSize={20} textAlign={'justify'} my={2}>
                    Visita el sitio de VGChile, la asociación que puede ayudarte a conectarte con empresas y conocer todos los apoyos públicos y privados disponibles para crear tu propia empresa de videojuegos en el país. ¡Descubre cómo puedes hacer realidad tus ideas y formar parte del creciente mercado de los videojuegos chilenos!
                </Typography>
                <Button variant='contained' onClick={() => { window.open("https://videogameschile.com/home/", '_blank') }}>
                    Conoce VGChile
                </Button>
            </Paper>
        </Box>
    )
}
