import React from 'react'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import image from "../../assets/imagen1.jpg"
import { useNavigate } from 'react-router-dom'
export const ComoParticipar = () => {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                paddingX: {
                    xl: 60,
                    lg: 40,
                    md: 20,
                    sm: 10,
                    xs: 5
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2
            }}
        >
            {/* <Typography variant='h3' fontWeight={"500"}>
                ¿Como participar?
            </Typography>
            <Paper elevation={6}
                sx={{
                    p: 2
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: "pre-wrap"
                    }}
                >
                    {"Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque inventore totam voluptatibus reiciendis dicta consequuntur iure, accusantium quod animi, exercitationem natus fuga dignissimos expedita tempore quam ab, nam laborum voluptatum.\n\nLorem ipsum dolor sit amet consectetur adipisicing elit. Neque inventore totam voluptatibus reiciendis dicta consequuntur iure, accusantium quod animi, exercitationem natus fuga dignissimos expedita tempore quam ab, nam laborum voluptatum."}
                </Typography>
            </Paper> */}
            <Grid container
                sx={{
                    minHeight: 500
                }}
            >
                <Grid item xs={12} md={6} minHeight={{ xs: 250, md: 'auto' }}>
                    <Box
                        sx={{
                            height: "100%",
                            width: "100%",
                            backgroundImage: `url(${image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: 'cover',
                            backgroundPosition: "center center"
                        }}
                    >
                        {/* <img src={image} alt="VGCParticipate" style={{ height: "auto", maxWidth: "100%" }} /> */}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 4
                    }}
                >
                    <Paper elevation={6} sx={{ p: 4 }}>
                        <Typography variant='h3' fontWeight={"500"} mb={2}>
                            ¿Como participar?
                        </Typography>
                        <Typography fontSize={18} mb={2}>
                            Simplemente, escoge el videojuego chileno de tu preferencia (nuevo, antiguo, demo, pago o F2P) sin importar la plataforma y sube contenido al respecto o trasmite stream jugando entre el 15 y el 19 de Mayo y ya estarás apoyando a la industria, recuerda utilizar el hashtag #VGCWeek para promocionar tu contenido.
                        </Typography>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => { navigate("/listado-videojuegos") }}
                        >
                            Listado de videojuegos chilenos
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}
