import { Box, Typography } from '@mui/material'
import React from 'react'
import logo from "../../assets/Logo VGCWeek - Variante_01-Negro.png"
import { Link } from 'react-router-dom'
import { AiFillInstagram, AiOutlineTwitter, AiOutlineMail } from "react-icons/ai";
export const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#EAEAEA",
                height: 350,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 2
            }}
        >
            <Box
                sx={{
                    width: 250
                }}
            >
                <img src={logo} alt="Bercomp Logo" width={"100%"} />
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                gap: 1
            }}>
                <Typography variant='h6'>Descubre cómo empezó la campaña</Typography>
                <Typography variant='h6' fontSize={16}>© 2023 Lady_Ixel</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 3
                    }}
                >
                    <AiFillInstagram size={30} style={{ cursor: 'pointer' }} onClick={() => { window.open("https://www.instagram.com/lady_ixel/", '_blank') }} />
                    <AiOutlineTwitter size={30} style={{ cursor: 'pointer' }} onClick={() => { window.open("https://twitter.com/lady_ixel", '_blank') }} />
                    <AiOutlineMail size={30} style={{ cursor: 'pointer' }} onClick={() => { window.open("mailto:vgcweek@gmail.com") }} />
                </Box>
            </Box>
            <Box
                sx={{
                    "a": {
                        color: "#0099ff",
                        textDecoration: "none",
                        fontWeight: "400",
                        fontSize: "1.3   rem"
                    },
                    display: "flex",
                    gap: 3
                }}
            >
                <Link to={"/"}>
                    Home
                </Link>
                <Link to={"/noticias"}>
                    Noticias
                </Link>
                <Link to={"/participantes"}>
                    Participantes
                </Link>
                <Link to={"/listado-videojuegos"}>
                    Listado de videojuegos
                </Link>
            </Box>

            <Typography variant="h2" component="h3" sx={{ fontSize: "0.875rem", textAlign: "center" }} gutterBottom >Powered By <a style={{ color: "inherit" }} href="https://tesseractsoftwares.com/en-us">Tesseract</a> ®</Typography>


        </Box >
    )
}
