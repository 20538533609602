import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
export const WhatIs = () => {

    return (
        <Box
            sx={{
                paddingX: {
                    xl: 60,
                    lg: 40,
                    md: 20,
                    sm: 10,
                    xs: 5
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2
            }}
        >
            <Typography variant='h3' fontWeight={"500"} textAlign={"center"}>
                ¿Que es VGCWeek?
            </Typography>
            <Paper elevation={6}
                sx={{
                    p: 2
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: "pre-wrap",
                        fontSize:20
                    }}
                >
                    {"#VGCWeek Es una iniciativa colectiva, independiente y sin fines de lucro, donde streamers, creadores de contenido y medios transmitirán y crearán contenido sobre los videojuegos chilenos durante una semana. Juntos podemos dar a conocer la creatividad y calidad de nuestra industria de videojuegos y apoyar a los desarrolladores locales. ¡Súmate a la iniciativa y ayúdanos a destacar el talento chileno en los videojuegos! #VGCWeek."}
                </Typography>
            </Paper>
        </Box>
    )
}
