import { Box } from '@mui/material'
import React from 'react'
import { Colaboradores } from './Colaboradores'
import { Desarrolladores } from './Desarrolladores'
import { CreadoresDeContenido } from './CreadoresDeContenido'

export const ParticipantsContainer = () => {
    return (
        <Box
            sx={{
                paddingX: {
                    xl: 60,
                    lg: 40,
                    md: 20,
                    sm: 10,
                    xs: 5
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                mt:4,
                "h4":{
                    color:"#cc3333"
                }
            }}
        >
            <Colaboradores/>
            <Desarrolladores/>
            <CreadoresDeContenido/>
        </Box>
    )
}
