import React from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import background from '../../assets/imagen1.jpg'
import { useNavigate } from 'react-router-dom'
import { Hero } from './Hero'
import { WhatIs } from './WhatIs'
import { ComoParticipar } from './ComoParticipar'
import { Graficas } from './Graficas'
import { Discord } from './Discord'
import { Desarrollo } from './Desarrollo'

export const HomeMain = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 6,

            }}
        >
            <Hero />
            <WhatIs />
            <ComoParticipar />
            <Graficas />
            <Discord />
            <Desarrollo />
        </Box >
    )
}
