import React from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import background from '../../assets/imagen1.jpg'
import { useNavigate } from 'react-router-dom'

export const Hero = () => {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                position: 'relative',
                height: 550,
            }}
        >
            <Box
                sx={{
                    backgroundImage: `url("${background}")`,
                    height: 550,
                    width: '100%',
                    position: 'absolute',
                    zIndex: '0',
                    filter: "blur(6px)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}
            >
            </Box>
            <Box
                sx={{
                    height: 550,
                    width: '100%',
                    position: 'absolute',
                    zIndex: '1',
                    backgroundColor: "rgba(0,0,0,0.5)"
                }}
            >
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 2,
                    position: 'absolute',
                    zIndex: '1000',
                    maxWidth: 400,
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)"
                }}
            >
                <Typography variant='h2' fontWeight={"bold"} sx={{ color: "#fff" }} >
                    {/* ¿Que es <span style={{ color: "#C00D0E" }}>BERCOMP</span>? */}
                    <span style={{ color: "#049cfc" }}>#</span><span style={{ color: "#cc3333" }}>VGC</span><span style={{ color: "#fff" }}>Week</span>
                </Typography>

                <Typography
                    sx={{ color: "#fff", textAlign: "justify" }}
                >
                    #VGCWeek Es una iniciativa colectiva, independiente y sin fines de lucro, donde streamers, creadores de contenido y medios transmitirán y crearán contenido sobre los videojuegos chilenos durante una semana. Juntos podemos dar a conocer la creatividad y calidad de nuestra industria de videojuegos y apoyar a los desarrolladores locales. ¡Súmate a la iniciativa y ayúdanos a destacar el talento chileno en los videojuegos! #VGCWeek.
                </Typography>
                <Button
                    variant='contained'
                    onClick={()=>{navigate('/participantes')}}
                >
                    Conoce a nuestros participantes
                </Button>
            </Box>
        </Box >
    )
}
