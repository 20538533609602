import { Avatar, Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Item } from './Item'
import imgNioZero from "../../assets/streamers/avatar_twitch - Gonzalo Hidalgo (NioZero).jpg"
import imgFraguGames from "../../assets/streamers/Fragugames1 - Franco Gutiérrez.png"
import imgGarciaNoctumbro from "../../assets/streamers/WhatsApp Image 2023-05-17 at 16.02.32 - Garcia Noctumbro.jpeg"
import imgr0gher from "../../assets/streamers/Opera Instantánea_2023-03-27_171538_www.instagram.com - Rogelio Garrido Navarrete.png"
import imgWerlingo from "../../assets/streamers/Werlingo logo - Mauricio Werlinger.png"
import imgSebastian from "../../assets/streamers/Captura de Pantalla 2023-05-17 a la(s) 16.07.59 - Sebastian Domingual.png"
import imgKrisEnigma from "../../assets/streamers/IMG_0329 - Kris Escobar.jpg"
import imgKleros from "../../assets/streamers/Screenshot_1 - Matías Mancilla.png"
import imgDvrk from "../../assets/streamers/QUIBBIhx_400x400 - Freddy Sandoval González.jpg"
import imgAlejoTSL from "../../assets/streamers/IMG_5392 - Alejandro Almonacid.jpeg"
import imgMrDudckDodgerss from "../../assets/streamers/83673812_119391039595933_2228855578698973184_n - Christopher Agurto.png"
import imgElRuso__gamer from "../../assets/streamers/_d970a70f-a3d4-4016-a4e5-e5e2f977cf9e - Elruso Gamer.jpg"
import imgCrazyfrogHzr from "../../assets/streamers/471B0C96-FF85-4612-A3D5-D7A52335B269 - Crazyfrog.jpeg"
import imgCalu from "../../assets/streamers/FOTO-PERFIL - Claudio Olave.jpg"
import imgThennecan from "../../assets/streamers/Cuadrada - Thennecan.jpg"
import imggabimartinarranz from "../../assets/streamers/4C8A924D-B57A-4806-A9E8-C76E332E52D4 - Gabriela Martin-Arranz.jpeg"
import imgHydeto from "../../assets/streamers/matia - Matias Tello.png"
import imgZoroalbo from "../../assets/streamers/LOGO CANAL - zoroalbo.jpeg"
import imgRulzB from "../../assets/streamers/Copy of IMG_8435 - Raul Bustamante.jpeg"
import imgDynamoXtyle from "../../assets/streamers/dynalogo1 - rafael romero.png"
import imgelsean3 from "../../assets/streamers/profile pic - Cristobal Fuentes.jpg"
import imgJovannieGamer from "../../assets/streamers/339154641_2175963879460280_7423803982894221224_n - Gino Figueroa B.jpg"
import imgCarowiza from "../../assets/streamers/InShot_20230518_085054396 - Carowiza.jpg"
export const CreadoresDeContenido = () => {

    const users = [
        {
            name: "NioZero",
            description: "Streamer",
            url: "https://twitch.tv/NioZero",
            img: imgNioZero
        },
        {
            name: "FraguGames",
            description: "Streamer",
            url: "https://twitch.tv/fragugames",
            img: imgFraguGames
        },
        {
            name: "GarciaNoctumbro",
            description: "Streamer",
            url: "https://www.twitch.tv/garcianoctumbro",
            img: imgGarciaNoctumbro
        },
        {
            name: "r0gher",
            description: "Streamer",
            url: "https://www.instagram.com/rogher.gn",
            img: imgr0gher
        },
        {
            name: "Werlingo",
            description: "Streamer",
            url: "https://www.instagram.com/werlingo/",
            img: imgWerlingo
        },
        {
            name: "Sebastián Domingual",
            description: "Streamer",
            url: "https://twitter.com/huevoctomate",
            img: imgSebastian
        },
        {
            name: "KrisEnigma",
            description: "Streamer",
            url: "http://www.youtube.com/",
            img: imgKrisEnigma
        },
        {
            name: "Kleros",
            description: "Streamer",
            url: "https://www.instagram.com/matias_kleros/",
            img: imgKleros
        },
        {
            name: "Dvrk",
            description: "Streamer",
            url: "https://www.instagram.com/ps.freddysandoval/",
            img: imgDvrk
        },
        {
            name: "AlejoTSL",
            description: "Streamer",
            url: "https://Twitch.tv/alejotsl",
            img: imgAlejoTSL
        },
        {
            name: "MrDudckDodgerss",
            description: "Streamer",
            url: "https://www.instagram.com/chris.dodgers/",
            img: imgMrDudckDodgerss
        },
        {
            name: "ElRuso__gamer",
            description: "Streamer",
            url: "https://www.instagram.com/elruso__gaming/",
            img: imgElRuso__gamer
        },
        {
            name: "CrazyfrogHzr",
            description: "Streamer",
            url: "https://m.facebook.com/crazyfroghazard1243?modal=admin_todo_tour&_rdr",
            img: imgCrazyfrogHzr
        },
        {
            name: "El baul del calu",
            description: "Streamer",
            url: "https://www.instagram.com/el_bauldecalu/?igshid=MzNlNGNkZWQ4Mg%3D%3D",
            img: imgCalu
        },
        {
            name: "Thennecan",
            description: "Streamer",
            url: "https://www.instagram.com/thennecan/",
            img: imgThennecan
        },
        {
            name: "gabimartinarranz",
            description: "Streamer",
            url: "https://www.instagram.com/gabimartinarranz/",
            img: imggabimartinarranz
        },
        {
            name: "Hydeto",
            description: "Streamer",
            url: "https://twitter.com/Hydeto050",
            img: imgHydeto
        },
        {
            name: "Zoroalbo",
            description: "Streamer",
            url: "https://www.twitch.tv/zoroalbo",
            img: imgZoroalbo
        },
        {
            name: "RulzB",
            description: "Streamer",
            url: "https://www.instagram.com/rulzbustamante/",
            img: imgRulzB
        },
        {
            name: "DynamoXtyle",
            description: "Streamer",
            url: "https://www.twitch.tv/dynamoxtyle",
            img: imgDynamoXtyle
        },
        {
            name: "elsean3",
            description: "Streamer",
            url: "https://www.twitch.tv/elsean3",
            img: imgelsean3
        },
        {
            name: "JovannieGamer",
            description: "Streamer",
            url: "https://twitch.tv/jovanniegamer",
            img: imgJovannieGamer
        },
        {
            name: "Carowiza",
            description: "Streamer",
            url: "https://instagram.com/carowiza",
            img: imgCarowiza
        },
    ]
    return (
        <Box>
            <Typography variant='h4' fontWeight={"500"} textAlign={"center"}>
                Creadores de contenido
            </Typography>
            <Grid container spacing={4} my={2}>
                {
                    users.map((item, index) => {
                        return (
                            <Item name={item.name} description={item.description} url={item.url} img={item.img} />
                        )
                    })
                }
            </Grid>
        </Box>
    )
}
