import React from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
interface Props {
    url: string,
    image: string,
    name: string,
    description: string,
    author: string
}
export const Item = ({ url, image, name, description, author }: Props) => {
    return (
        <Paper elevation={6} sx={{ cursor: "pointer" }} onClick={() => { window.open(url, '_blank') }}
        >

            <Grid container p={4} sx={{ minHeight: 150, alignItems: 'center' }} spacing={4}>

                <Grid item xs={12} md={3}

                >
                    <Box
                        sx={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            height: { xs: 150}
                        }}
                    >
                    </Box>
                </Grid>

                <Grid item xs={12} md={9}>
                    <Box>
                        <Typography variant='h5' fontWeight={500}  color={"primary"}>
                            {name}
                        </Typography>
                        <Typography variant='subtitle2'  color={"secondary"}>
                            {author}
                        </Typography>
                        <Typography variant='body1' fontSize={20}
                            sx={{
                                display: "-webkit-box",
                                overflow: 'hidden',
                                textOverflow: "ellipsis",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: { xs: 5 },
                                textAlign: "justify"
                            }}>
                            {description}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Paper>

    )
}
