import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { BsDiscord } from "react-icons/bs";
import image from '../../assets/discord.jpg'
export const Discord = () => {
    return (
        <Box
            sx={{
                paddingX: {
                    xl: 60,
                    lg: 40,
                    md: 20,
                    sm: 10,
                    xs: 5
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2
            }}
        >
            <Grid container
                sx={{
                    minHeight: 500
                }}
            >
                <Grid item xs={12} md={6} minHeight={{ xs: 250, md: 'auto' }}>
                    <Box
                        sx={{
                            height: "100%",
                            width: "100%",
                            backgroundImage: `url(${image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: 'cover',
                            backgroundPosition: "center center"
                        }}
                    >
                        {/* <img src={image} alt="VGCParticipate" style={{ height: "auto", maxWidth: "100%" }} /> */}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 4
                    }}
                >
                    <Paper elevation={6} sx={{ p: 4 }}>
                        <Typography variant='h3' fontWeight={"500"} mb={2}>
                            Conoce a la comunidad
                        </Typography>
                        <Typography fontSize={18} mb={2}>
                            Si quieres participar aún más en esta iniciativa, únete a nuestro servidor de Discord donde podrás encontrar a desarrolladores, creadores de contenido y colaboradores de la campaña.
                        </Typography>
                        <Button variant='contained' onClick={() => { window.open("https://discord.gg/FmbhRwtJCt", '_blank') }}>
                            Unete a nuestro servidor
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}
