import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import imagen1 from "../../assets/imagen1.jpg"
export const Graficas = () => {
    return (
        <Box
            sx={{
                paddingX: {
                    xl: 60,
                    lg: 40,
                    md: 20,
                    sm: 10,
                    xs: 5
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                height: { xs: 600, md: 500 }
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    width: "100%",
                    position: "relative"
                }}
            >

                <Box
                    sx={{
                        clipPath: { xs: "polygon(0 50%, 0% 100%, 100% 100%, 100% 50%)", md: "polygon(0 60%, 0% 100%, 100% 100%, 100% 60%)" },
                        backgroundColor: "rgba( 0, 46, 84 ,0.8)",
                        height: '100%',
                        width: "100%",
                        position: "absolute",
                        left: 0,
                        top: 0
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            left: "0",
                            textAlign: "start",
                            width: "100%",
                            height: { xs: "50%", md: "40%" },
                            p: 1,
                            color: "#fff",
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1

                            // transform:"translate(-50%,-90%)"

                        }}
                    >
                        <Typography
                            variant='h5'
                            fontWeight={"bold"}
                        >
                            Logo y gráficas de participante
                        </Typography>
                        <Typography
                            variant='body1'
                        >
                            ¿Te interesa agregar a tu contenido un logo que indique que estás apoyando la campaña?, acá lo podrás encontrar, te pedimos no intervenirlo para mantener la identidad de la campaña.
                        </Typography>
                        <Box>
                            <Button variant='contained' onClick={() => { window.open("https://drive.google.com/drive/folders/11BzM8z0-RD3tBJ-2NzHk5kGKXE2kIVzT", '_blank') }}>
                                Descargalos aca
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                        backgroundImage: `url(${imagen1})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: 'cover',
                        backgroundPosition: "center center"
                    }}
                >
                </Box>
            </Box>
        </Box>
    )
}
